<template>
  <BulletsSlider
    v-if="categories && categories.length"
    v-slot="{ item }"
    :items="categories.filter(category => category.url_key)"
    :selected-item="categories"
    type="secondary"
    class="flex-1 pl-5 md:pl-0"
    :large-screen-attributes="{
      slidesPerView: 'auto',
    }"
    auto
  >
    <button
      class="px-6 py-3 border text-base whitespace-nowrap capitalize rounded-8sm"
      :class="{
        'bg-primary-1-100 text-white font-medium border-primary-1-100': category.id === item.id,
        'bg-transparent text-primary-1-100 font-medium border-primary-1-04': category.id !== item.id,
      }"
      @click="onSelectCategory(item)"
    >
      {{ item.name.toLowerCase() }}
    </button>
  </BulletsSlider>
</template>
<script setup lang="ts">
import type { Category } from '@robustastudio/e-commerce/common';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const gradientClasses = ['gradient-primary-700', 'gradient-primary-600', 'gradient-primary-800'];
defineComponent({
  name: 'CategorySlider',
});

const props = defineProps({
  category: {
    type: Object as PropType<Category>,
    default: () => ({}),
  },
});

const { categories, category: selectedCategory } = useCategorySlider() as {
  categories: Ref<Category[]>;
  category: Ref<Category>;
};

/**
 * on mounted set the selected category component to the one injected
 */
onMounted(() => {
  /**
   * set the selected category
   */
  selectedCategory.value = props.category;
});

const emit = defineEmits<(event: 'category', url: string) => void>();

function onSelectCategory(category: Category) {
  if (!(category.url || category.url_key)) {
    return;
  }
  /**
   * set the selected category
   */

  emit('category', `${category.url || category.url_key}`);
}
</script>
