<template>
  <ProductPage />
</template>

<script setup lang="ts">
import type { ProductData } from '@robustastudio/e-commerce/common';

defineComponent({
  name: 'ProductWrapper',
});

const props = defineProps({
  product: {
    type: Object as PropType<ProductData>,
    default: null,
  },
});

provide(PRODUCT_CONTEXT, ref(props.product));

const ProductPage = defineAsyncComponent(() => import('./ProductContent.vue'));
</script>
