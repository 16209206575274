<template>
  <div v-if="pending" class="w-full container mx-auto py-12 h-full flex items-center justify-center">
    <LoadingProductsListPage class="w-full" />
  </div>
  <ContainersCategoryContent v-else-if="data && data.type === 'category'" :category="data?.pageData" />

  <ContainersProductWrapper v-else-if="data && data.type === 'product'" :product="data?.pageData" />
</template>

<script setup lang="ts">
import type { Category, ProductData } from '@robustastudio/e-commerce/common';

definePageMeta({
  /**
   * Prevents re-rendering if the path is a variant of a product
   */
  key: route => {
    const parts = route.params.slug as string[];

    // is variant path
    if (parts.includes('var') || parts.length === 1) {
      return parts[0];
    }

    if (parts.length > 1) {
      return parts[parts.length - 1];
    }

    return route.fullPath;
  },
});
/**
 * A Helper Method that extracts the slug parameter from the url
 */
const extractSlugFromUrl = (parts: string[]) => {
  if (!parts.length) return '';
  // check if it contains var
  if (parts.includes('var')) {
    return parts.at(0);
  }

  return parts.at(parts.length - 1);
};

const route = useRoute();

type AsyncData =
  | {
      type: 'product';
      pageData: ProductData;
    }
  | {
      type: 'category';
      pageData: Category;
    }
  | null;

const { pending, data } = useAsyncData<AsyncData>(async () => {
  const content = await useResolveSlugParameter(extractSlugFromUrl(route.params.slug as string[]) || '');
  if (content?.type === 'product') {
    return {
      type: 'product',
      pageData: content?.payload as ProductData,
    };
  }

  if (content?.type === 'category') {
    return {
      type: 'category',
      pageData: content?.payload as Category,
    };
  }

  return null;
});
</script>
